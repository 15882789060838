@import 'styles/breakpoints';
@import 'styles/mixins';

.locationCard {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: var(--block-padding-end) 2.4rem;
    background: var(--colors-white);
    color: var(--colors-blue);

    @include rounded-corners;
}

.logoWrapper {
    position: relative;
    width: 26.4rem;
}

.address {
    margin-top: 3rem;
    text-align: center;
    text-decoration: underline;

    .market & {
        max-width: 34rem;
    }
}

.reservationButton {
    margin-top: 2.4rem;
}

.hoursWrapper {
    width: 100%;
    margin-top: 4.8rem;
}

.hoursLabel {
    text-transform: uppercase;
}

.hoursList {
    padding: 0;
    margin: 1rem 0 2rem;
    list-style: none;

    li {
        &::after {
            display: block;
            width: 100%;
            height: 0.1rem;
            background-color: var(--colors-blue);
            content: '';
            opacity: 0.25;
        }
    }
}

.timeSpan {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.contactWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 4rem;
}

.contactLabel {
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.contactName,
.contactEmail,
.contactNumber {
    margin-bottom: 0.5rem;
}

.contactEmail,
.contactNumber {
    text-decoration: underline;
}

.buttonWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    gap: 1.2rem;
}

@include large {
    .logoWrapper {
        width: 27.4rem;
    }

    .buttonWrapper {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .learnMoreLink {
        width: 100%;
    }
}

@include xxLarge {
    .logoWrapper {
        --logo-width: 29.8rem;
    }

    .address {
        --address-max-width: 29.8rem;
    }

    .locationCard {
        padding: var(--block-padding-end);
    }

    .buttonWrapper {
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
    }
}
